<template>
  <section v-if="information">
    <div :key="information.functionbox.functionbox_uuid"> 

      <h3 class="pt-0 mt-0 mb-0">
        {{ information.functionbox.name }} <br />
        <small>
          {{ information.functionbox.email }}
          <span class="hidden-mobile">
            <span v-if="information.functionbox.sdk_identifier">&nbsp;<img
              class="icon"
              src="/img/message_type/sdk-message.png"
              :alt="$t('MESSAGES.TYPE.sdk-message')"
            /> {{ information.functionbox.sdk_identifier }}</span>
            <span v-if="information.functionbox.fax_number">&nbsp;<img
              class="icon"
              src="/img/message_type/fax-message.png"
              :alt="$t('MESSAGES.TYPE.fax-message')"
            /> {{ information.functionbox.fax_number }}</span>
          </span>
          <span class="hidden-not-mobile">
            <span v-if="information.functionbox.sdk_identifier"><br /><img
              class="icon"
              src="/img/message_type/sdk-message.png"
              :alt="$t('MESSAGES.TYPE.sdk-message')"
            /> {{ information.functionbox.sdk_identifier }}</span>
            <span v-if="information.functionbox.fax_number"><br /><img
              class="icon"
              src="/img/message_type/fax-message.png"
              :alt="$t('MESSAGES.TYPE.fax-message')"
            /> {{ information.functionbox.fax_number }}</span>
          </span>
        </small>
        
      </h3>
      <b-alert
        show
        variant="warning"
        v-if="information.functionbox.verified == 0"
        >{{ $t("NOT_VERIFIED") }}</b-alert
      >
      <b-nav tabs class="hidden-mobile">
        <b-nav-item :active="tab == 0" @click="tab = 0">{{$t('INFORMATION')}}</b-nav-item>
        <b-nav-item :active="tab == 1" @click="tab = 1" v-if="information.functionbox.verified == 1">{{$t('ACCOUNTS')}}</b-nav-item>
        <b-nav-item :active="tab == 2" @click="tab = 2" v-if="!editUsers">{{$t('SETTINGS')}}</b-nav-item>
        <b-nav-item :active="tab == 3" @click="tab = 3" v-if="information.functionbox.verified == 1 && user.information.is_admin != 0 && !editUsers">{{$t('API')}}</b-nav-item>
      </b-nav>
      
      <b-dropdown class="hidden-not-mobile" toggle-class="no-border">
        <template #button-content>
          <span v-if="tab == 0">{{$t('INFORMATION') }}</span>
          <span v-if="tab == 1">{{$t('ACCOUNTS') }}</span>
          <span v-if="tab == 2">{{$t('SETTINGS') }}</span>
          <span v-if="tab == 3 && user.information.is_admin != 0">{{$t('API') }}</span>
        </template>
        <b-dropdown-item :active="tab == 0" @click="tab = 0">{{$t('INFORMATION')}}</b-dropdown-item>
        <b-dropdown-item :active="tab == 1" @click="tab = 1" v-if="information.functionbox.verified == 1">{{$t('ACCOUNTS')}}</b-dropdown-item>
        <b-dropdown-item :active="tab == 2" @click="tab = 2" v-if="!editUsers">{{$t('SETTINGS')}}</b-dropdown-item>
        <b-dropdown-item :active="tab == 3" @click="tab = 3" v-if="!editUsers">{{$t('API')}}</b-dropdown-item>
      </b-dropdown>


      <div v-if="tab == 2">
        <b-nav tabs class="hidden-mobile">
          <b-nav-item :active="setting == 0" @click="setting = 0">{{$t('INFORMATION')}}</b-nav-item>
          <b-nav-item :active="setting == 1" @click="setting = 1" v-if="information.functionbox.verified == 1">{{$t('EMAIL')}}</b-nav-item>
          <b-nav-item :active="setting == 2" @click="setting = 2" v-if="showExternalSystems && !editUsers && information.functionbox.verified == 1">{{$t('EXTERNAL_SYSTEMS')}}</b-nav-item>
          <b-nav-item :active="setting == 3" @click="setting = 3" v-if="information.functionbox.verified == 1">{{$t('ADDRESSBOOK.TITLE')}}</b-nav-item>
          <b-nav-item :active="setting == 4" @click="setting = 4" v-if="information.functionbox.verified == 1">{{$t('ALL_MESSAGES')}}</b-nav-item>
        </b-nav>
        
        <b-dropdown class="hidden-not-mobile" v-if="tab == 2" toggle-class="no-border">
          <template #button-content>
            <span v-if="setting == 0">{{$t('INFORMATION') }}</span>
            <span v-if="setting == 1">{{$t('EMAIL') }}</span>
            <span v-if="setting == 2" >{{$t('EXTERNAL_SYSTEMS') }}</span>
            <span v-if="setting == 3">{{$t('ADDRESSBOOK.TITLE') }}</span>
            <span v-if="setting == 4">{{$t('ALL_MESSAGES') }}</span>
          </template>
          <b-dropdown-item :active="setting == 0" @click="setting = 0">{{$t('INFORMATION')}}</b-dropdown-item>
          <b-dropdown-item :active="setting == 1" @click="setting = 1" v-if="information.functionbox.verified == 1">{{$t('EMAIL')}}</b-dropdown-item>
          <b-dropdown-item :active="setting == 2" @click="setting = 2" v-if="showExternalSystems && !editUsers && information.functionbox.verified == 1">{{$t('EXTERNAL_SYSTEMS')}}</b-dropdown-item>
          <b-dropdown-item :active="setting == 3" @click="setting = 3" v-if="information.functionbox.verified == 1">{{$t('ADDRESSBOOK.TITLE')}}</b-dropdown-item>
          <b-dropdown-item :active="setting == 4" @click="setting = 4" v-if="information.functionbox.verified == 1">{{$t('ALL_MESSAGES')}}</b-dropdown-item>
        </b-dropdown>

        <Edit v-if="!editUsers && setting == 0"
          :tabIndex="0"
          @updatedInfo="updatedInfo"
          @removedUser="removedBox"
          :information="information"
        ></Edit>
        
        <Edit v-if="!editUsers && setting == 1"
          :tabIndex="1"
          @updatedInfo="updatedInfo"
          @removedUser="removedBox"
          :information="information"
        ></Edit>

        <GeneralSetting 
          :email="information.functionbox.email"
          :organisation_uuid="information.functionbox.organisation_uuid" 
          :functionbox_uuid="functionbox_uuid" v-if="setting == 4"></GeneralSetting>

        <ExternalSystems :information="information" v-if="showExternalSystems && !editUsers && setting == 2"></ExternalSystems>
          
        <SettingAddressBook
          v-if="showAddressbook && !editUsers && information.functionbox.organisation_uuid  && setting == 3"
          :organisation_uuid="information.functionbox.organisation_uuid"
          :functionbox_uuid="functionbox_uuid"></SettingAddressBook>

      </div>

      <Information v-if="tab == 0" @updatedInfo="updatedInfo" :information="information"></Information>

      <EditUsers v-if="information.functionbox.verified == 1 && tab == 1"
        @updatedUsers="updatedUsers"
        :information="information"
      ></EditUsers>    

      <API :information="information" v-if="!editUsers && tab == 3"></API>      

    </div>
  </section>
</template>
<script>
import Edit from "@/components/FunctionBox/Edit";
import API from "@/components/FunctionBox/API";
import Information from "@/components/FunctionBox/Information";
import EditUsers from "@/components/FunctionBox/EditUsers";
import ExternalSystems from "@/components/FunctionBox/ExternalSystems";
import GeneralSetting from "@/components/FunctionBox/GeneralSetting";
import SettingAddressBook from "@/components/Setting/AddressBook";
export default {
  props: {
    functionbox_uuid: {
      type: String,
      default: null
    },
    editUsers: {
      type: Boolean,
      validator(value) {
        return value != undefined;
      },
    }
  },
  components: { GeneralSetting, ExternalSystems, Edit, Information, EditUsers,API, SettingAddressBook },
  data() {
    return {
      setting: 0,
      tab: 0,
      information: null,
      organisation: null
    };
  },
  methods: {
    updatedInfo: function() {
      this.getInformation();
    },
    updatedUsers: function() {},
    removedBox: function() {
      this.$emit("removedBox");
    },
    async getInformation() {
      try {
        let response = await this.$http.get(this.user.hostname + "/administration/functionbox/information/" + this.functionbox_uuid);
        this.information = response.data;
      } catch {
        console.log("Could not fetch getInformation");
      }
    },
  },
  watch: {
    functionbox_uuid: function(newVal, oldVal) {
      if (newVal != oldVal) {
        this.getInformation();
      }
    },
  },
  computed: {
    showExternalSystems(){
      return this.user.information.is_admin >= 1;
    },
    showAddressbook(){
      return this.user.information.is_admin >= 1;
    },
  },
  mounted() {
    this.getInformation();
  },
};
</script>
<style></style>
