<template>
  <section class="mt-2" :key="tableKey">
    <b-overlay :show="loading" rounded="sm">
      <div v-if="added">
        <pre></pre>

        <b-card>
          <b-card-sub-title class="ml-0 pl-0">{{
            $t("FUNCTIONBOX.NEW_API")
          }}</b-card-sub-title>
          <b-card-text>
            {{ token }}
          </b-card-text>

          <b-button size="sm" @click="closeAdded">{{ $t("CLOSE") }}</b-button>
        </b-card>
      </div>

      <div v-if="!added">
        <b-form inline class="mt-2">
          <b-button @click="refreshList" class="no-border"
            ><i class="fal fa-sync"></i
          ></b-button>
          <b-btn
            class="inlineBtn"
            variant="dark"
            @click="functionboxApi = true"
          >
            {{ $t("ADD") }}
          </b-btn>
          <label class="sr-only" for="inline-form-input-search">{{$t('SEARCH')}}</label>
          <b-form-input trim
            v-model="searchText"
            :placeholder="$t('SEARCH')"
            @keydown.enter.prevent
            class="searchText hidden-mobile ml-0"
          ></b-form-input>

          
          
          <div class="hidden-not-mobile w-100 mt-2">
            <b-form inline>
              <b-form-input trim
                class="ml-2 i-100 searchText"
                v-model="searchText"
                :placeholder="$t('SEARCH')"
                @keydown.enter.prevent
              ></b-form-input>
            </b-form>
          </div>
        </b-form>

        <b-table
          small
          class="mt-2"
          :busy="loading"
          bordered
          :items="items"
          :fields="fields"
        >
          <template #head(id)>
            <b-form-checkbox
              v-if="items.length != 0"
              v-model="remove_all"
              @change="removeAll"
            ></b-form-checkbox>
            &nbsp;
          </template>

          <template v-slot:cell(id)="data">
            <b-form inline>
              <b-form-checkbox
                v-model="id_list"
                :value="data.item.id"
              ></b-form-checkbox>
            </b-form>
          </template>

          <template v-slot:cell(description)="data">
            <div class="hidden-not-mobile">
              {{ data.item.description }}<br />
              {{ $t('CREATED') }} {{ FormatDateTime(data.item.created_date) }}<br />
              {{ $t('EXPIRES') }} {{ FormatDateTime(data.item.expire_date) }}
            </div>
            <div class="hidden-mobile">
              {{ data.item.description }}
            </div>
          </template>

          <template v-slot:cell(created_date)="data">{{
            FormatDateTime(data.item.created_date)
          }}</template>

          <template v-slot:cell(expire_date)="data">{{
            FormatDateTime(data.item.expire_date)
          }}</template>
          <template #table-busy>
            <div class="text-center my-2">
              <b-spinner class="align-middle"></b-spinner>
            </div>
          </template>
        </b-table>

        <b-pagination
          class="mt-2"
          v-model="currentPage"
          :total-rows="totalItems"
          :per-page="limit"
          align="center"
          pills
          @input="getItems"
        >
        </b-pagination>

        <hr class="p-0 m-0 mt-2 mb-2" />

        <b-button
          :disabled="!validRemove"
          variant="danger"
          class="btn-fill"
          @click="removeBtn"
          >{{ $t("REMOVE") }}</b-button
        >
      </div>

      <b-modal
        id="add-functionbox-api"
        :dialog-class="teams.theme"
        v-model="functionboxApi"
        hide-header
        @cancel="closeAdded"
        @ok="addBtn"
        centered
        :cancel-title="$t('CANCEL')"
        :ok-title="$t('ADD')"
      >
        <p>{{ $t('FUNCTIONBOX_API_COST') }}</p>
        <b-form-input trim
          autocomplete="off"
          :placeholder="$t('NAME')"
          v-model="add_description"
        ></b-form-input>
      </b-modal>
    </b-overlay>
  </section>
</template>
<script>
export default {
  props: ["information"],
  data() {
    return {
      add_description: "",
      functionboxApi: false,
      tableIndex: 0,
      added: false,
      id_list: [],
      token: "",
      remove_all: false,
      loading: false,
      searchText: "",
      limit: 10,
      currentPage: 1,
      items: [],
      totalItems: 0,
      fields: [
        {
          key: "id",
          class: "",
        },
        {
          key: "created_date",
          label: this.$t("CREATED"),
          class: "w-300 text-break hidden-mobile",
          sortable: false,
        },
        {
          key: "expire_date",
          label: this.$t("EXPIRE"),
          class: "w-300 text-break hidden-mobile",
          sortable: false,
        },
        {
          key: "description",
          label: this.$t("NAME"),
          class: "w-300 text-break",
          sortable: false,
        },
      ],
    };
  },
  methods: {
    closeAdded: function () {
      this.added = false;
      this.add_description = "";
      this.refreshList();
    },
    refreshList: function () {
      this.getItems();
      this.tableIndex = this.tableIndex + 1;
    },
    removeAll: function () {
      if (this.remove_all) {
        this.id_list = this.items.map((a) => a.id);
      } else {
        this.id_list = [];
      }
    },
    addBtn: function () {
      this.loading = true;
      let self = this;
      this.$http
        .post(this.user.hostname + "/administration/functionbox/api/add", {
          description: this.add_description,
          functionbox_uuid: this.information.functionbox.functionbox_uuid,
        })
        .then((response) => {
          self.token = response.data;
          self.add_description = "";
          self.loading = false;
          self.added = true;
        })
        .catch(() => {
          self.loading = false;
        });
    },
    getItems: function () {
      this.loading = true;
      let self = this;
      this.$http
        .post(this.user.hostname + "/administration/functionbox/api/list", {
          page: this.currentPage,
          functionbox_uuid: this.information.functionbox.functionbox_uuid,
          state: 1,
          limit: parseInt(this.limit),
          search_text: this.searchText,
        })
        .then((response) => {
          self.loading = false;
          self.items = response.data.items;
          self.totalItems = response.data.count;
        })
        .catch(() => {
          self.loading = false;
        });
    },
    removeBtn: function () {
      let self = this;
      this.$bvModal
        .msgBoxConfirm(this.$t("ARE_YOU_SURE"), {
          size: "sm",
          buttonSize: "sm",
          okVariant: "primary",
          okTitle: this.$t("YES"),
          cancelTitle: this.$t("NO"),
          bodyClass: "messagebox",
          footerClass: "p-2  text-center messagebox",
          hideHeaderClose: false,
          centered: true,
        })
        .then(function (value) {
          if (value) {
            self.loading = true;
            self.$http
              .post(
                self.user.hostname + "/administration/functionbox/api/remove",
                {
                  id_list: self.id_list,
                  functionbox_uuid:
                    self.information.functionbox.functionbox_uuid,
                }
              )
              .then(() => {
                self.getItems();
                self.refreshList();
                self.id_list = [];
                self.loading = false;
              })
              .catch(() => {
                self.loading = false;
              });
          }
        })
        .catch(function () {});
    },
  },
  computed: {
    tableKey: function () {
      return this.tableIndex + "-api";
    },
    validRemove: function () {
      return this.id_list != 0;
    },
  },
  mounted() {
    this.getItems();
  },
};
</script>
<style></style>
